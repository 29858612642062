import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, identity } from 'rxjs';
import { DocumentService } from '../document.service';
import { DocumentMetadata } from '../document-metadata';
import { switchMap, map, filter, tap, take, first } from 'rxjs/operators';
import { DocuSignupDialogComponent } from '../docu-signup-dialog/docu-signup-dialog.component';
import { AppDialogService } from 'app/shared/dialogs';
import { RoutesService } from 'app/routes.service';

@Component({
  selector: 'app-docu-preview',
  templateUrl: './docu-preview.component.html',
  styleUrls: ['./docu-preview.component.scss'],
})
export class DocuPreviewComponent implements OnInit {
  @Input() public id: Observable<number>;
  public document: Observable<DocumentMetadata>;
  public pdfUrl: Observable<SafeResourceUrl>;

  private docId: number;

  constructor(
    private documentService: DocumentService,
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private dialogService: AppDialogService,
    private router: Router,
    private routesService: RoutesService
  ) {
    if (this.id === undefined) {
      this.id = this.activatedRoute.paramMap.pipe(
        map(params => Number(params.get('id')))
      );
    }
  }

  ngOnInit() {
    this.document = this.id.pipe(
      switchMap(id => this.documentService.getMetadata(id))
    );
    this.id.pipe(first()).subscribe(id => {
      this.docId = id;
    });

    this.pdfUrl = this.document.pipe(
      map(doc =>
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          this.documentService.getPdfUrl(doc.id, true) + '#zoom=page-width'
        )
      )
    );
  }

  notFoundFilter(doc: DocumentMetadata): boolean {
    return doc === undefined;
  }

  public showSignupForm(): void {
    const dialog = this.dialogService.openComponent(DocuSignupDialogComponent);
    dialog.onClose.pipe(first()).subscribe(success => {
      if (!success) {
        return;
      }

      this.routesService
        .setRoutes()
        .pipe(first())
        .subscribe(_ => {
          const editUrl = '/docu/edit/' + this.docId;
          this.router.navigate([editUrl]);
        });
    });
  }
}
