<div>
  <a
    *ngIf="showLinkToMainAccount"
    href="/CompanyHasCompany/restoreToMainAccount"
    >Återgå till huvudkonto</a
  >
  <div *ngIf="hasCrmRights">
    <a href="/mimas"> Till admin</a>

    <form (ngSubmit)="submitForm()">
      <input
        type="text"
        [(ngModel)]="searchCompanyName"
        [ngModelOptions]="{ standalone: true }"
        placeholder="Sök företag..."
      />
    </form>
  </div>
</div>
