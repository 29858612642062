import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { InputTextModule } from 'primeng/inputtext';

import { AuthService } from 'app/shared/auth/auth.service';
import { CompanyAppParamsService } from '../shared/company/index';
import { MessageService } from '../shared/message/index';
import { GlobalService } from '../shared/global/index';
import { HyperionErrorHandler } from 'app/shared/errors';
import { MeUserWithCompany } from 'app/shared/user/me-user';
import { UserLocalStorageService } from 'app/shared/user';
import { ConfigService } from 'app/shared/config/config.service';
import { RoutesService } from 'app/routes.service';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
// ROLL BASERAT PÅ OM ANVÄNDAREN FÅR VARA INNE, SKICKA VIDARE OCH MEDDELA, OM INTE FÅR VARA INNE MEDDELA
export class LoginComponent implements AfterViewInit, OnInit, OnDestroy {
  public message: string;
  public loading = false;
  public appURL: string;
  public email = '';
  public password = '';
  public companyInfo: any;
  public meUser: MeUserWithCompany;
  public userNameModel: string;
  public reset = true;
  public rememberMe = false;
  public displaySendPassword = false;
  public isAccountCreationAllowed = false;
  public appLogoPath: string = null;
  public logoLoading = true;
  public loginSub: Subscription;
  public isDerome: boolean;

  constructor(
    public authService: AuthService,
    public router: Router,
    private companyAppParamsService: CompanyAppParamsService,
    private messageService: MessageService,
    private globalService: GlobalService,
    private http: HttpClient,
    private errorHandler: HyperionErrorHandler,
    private userLocalStorageService: UserLocalStorageService,
    private configService: ConfigService,
    private routesService: RoutesService
  ) {}

  public ngAfterViewInit(): void {
    this.checkIfLoggedIn().subscribe(data => {
      if (this.authService.isLoggedIn) {
        this.meUser = this.userLocalStorageService.getMeUserWithCompany();

        if (!this.meUser) {
          this.logout();
        }
      }
    });
  }

  public ngOnInit(): void {
    this.appURL = localStorage.getItem('appappURL');
    const config = this.configService.getConfig();
    this.setAppLogoPath(config.theme.logoPath);

    this.companyAppParamsService.appParams.pipe(first()).subscribe(data => {
      this.isAccountCreationAllowed = data.isAccountCreationAllowed === 'true';
      this.isDerome = data.getenvStruqturMode === 'derome';
    });

    this.routesService.setRoutes().pipe(first()).subscribe();
  }

  private setAppLogoPath(appLogoPath): void {
    this.appLogoPath = this.globalService.getUrlPrefix() + appLogoPath;

    if (typeof appLogoPath !== 'undefined') {
      this.logoLoading = false;
    }
  }

  private checkIfLoggedIn(): Observable<boolean> {
    return this.authService.checkStatus();
  }

  public login(): void {
    this.loading = true;

    const rememberMeInt = this.rememberMe ? 1 : 0;

    this.loginSub = this.authService
      .login(this.email, this.password, rememberMeInt)
      .subscribe({
        next: authData => {
          if (this.authService.isLoggedIn) {
            // if user login request is succesful
            this.displaySendPassword = false;

            this.routesService
              .setRoutes()
              .pipe(first())
              .subscribe(routes => {
                this.router.navigateByUrl(this.authService.redirectUrl);
              });
          } else {
            // user login request is NOT succesfull
            this.displaySendPassword = true;
            this.messageService.handleResponse(authData);
            this.loading = false;
          }
        },
        error: error => {
          this.errorHandler.handleError(error);
        },
      });
  }

  public sendNewPassword(): Promise<void> {
    const url =
      this.globalService.getUrlPrefix() +
      '/site/SendNewPassword?username=' +
      this.userNameModel;

    return this.http
      .get(url)
      .toPromise()
      .then(data => {
        this.messageService.insertData({
          textArray: [data['msg']],
          type: data['status'],
        });
      });
  }

  public ngOnDestroy(): void {
    if (this.loading) {
      this.loading = false;
    }

    if (typeof this.loginSub !== 'undefined') {
      this.loginSub.unsubscribe();
    }

    this.email = '';
    this.password = '';
  }

  public goToHome(): void {
    this.router.navigate(['/']);
  }

  public logout(): void {
    this.authService.logout().subscribe();
  }

  public handleKeyUp(event): void {
    if (event.keyCode === 13) {
      this.login();
    }
  }
}
