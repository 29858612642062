import { ApolloClient } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MenuService } from 'app/shared/menu/menu.service';

import { UserLocalStorageService } from 'app/shared/user/user-local-storage.service';

import {
  Observable,
  Subject,
  from as observableFrom,
  of,
  NextObserver,
  BehaviorSubject,
} from 'rxjs';
import {
  AppStateService,
  AppStates,
  appStateOptions,
} from 'app/store/app-state.service';
import { GlobalService } from 'app/shared/global/global.service';
import { map, tap, switchMap } from 'rxjs/operators';

import { MessageService } from 'app/shared/message';
import { mutations } from 'app/shared/apollo/mutations-hardcoded';
import { VersionCheckService } from 'app/shared/version-check/version-check.service';

interface AuthRegisterParams {
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
  company: {
    name: string;
    phone: string;
    orgNr: string;
  };
}

// ROLL KOLLA OM ANVÄNDAREN FÅR VARA INNE
@Injectable()
export class AuthService {
  // store the URL so we can redirect after logging in
  public redirectUrl: string;
  private isLoggedInSubject: BehaviorSubject<boolean>;

  // @deprecated: you probably want to use isLoggedInObs instead
  get isLoggedIn(): boolean {
    return this.isLoggedInSubject.value;
  }

  get isLoggedInObs(): Observable<boolean> {
    return this.isLoggedInSubject;
  }

  public authenticatedChange: Subject<boolean> = new Subject();

  private urlPrefix;

  private apolloClient: ApolloClient<any>;

  private response;

  private stateOptions: AppStates;

  public gotLoggedOut = new Subject();

  constructor(
    private http: HttpClient,
    private meUser: UserLocalStorageService,
    private globalService: GlobalService,
    private apollo: Apollo,
    private menuService: MenuService,
    private appStateService: AppStateService,
    private messageService: MessageService,
    private versionCheckService: VersionCheckService
  ) {
    this.stateOptions = appStateOptions;
    this.urlPrefix = this.globalService.getUrlPrefix();

    this.apolloClient = this.apollo.getClient();

    this.isLoggedInSubject = new BehaviorSubject(false);
  }

  private isLoggedOut() {
    this.eraseRoutesForClient();
    this.clearLocaleStorage();
    this.response = null;
    this.isLoggedInSubject.next(false);

    this.gotLoggedOut.next(true);

    this.appStateService.setAppState(this.stateOptions.LOGGED_OUT);
  }

  private handleResponse(serverResponse) {
    // here i can set this this.setUserLocaleStorage(); if serverresponse is true
    if (!serverResponse) {
      return;
    }

    this.response = serverResponse;
    const status: boolean = serverResponse.status;

    if (
      status &&
      serverResponse.user == null &&
      serverResponse.redirectUrl &&
      serverResponse.redirectUrl.match(/\/privatecostumer/i)
    ) {
      window.location.href = serverResponse.redirectUrl;
    }
    status &&
      localStorage.getItem('MEfirstName') === null &&
      this.setUserLocaleStorage();
    this.isLoggedInSubject.next(!!status);
    this.authenticatedChange.next(!!status);

    if (serverResponse.version) {
      this.versionCheckService.shouldHardReloadApp(serverResponse.version.hash);
    }

    if (!status) {
      this.appStateService.setAppState(this.stateOptions.LOGGED_OUT);
    }
  }

  /* --- CALLBACK METHODS --- */
  public login(
    username: string,
    password: string,
    rememberMe = 0
  ): Observable<any> {
    return this.sendLoginRequestToServer(username, password, rememberMe).pipe(
      tap(res => {
        this.handleResponse(res);
      })
    );
  }

  public logout(): Observable<void> {
    const url = this.urlPrefix + '/hyperion/aut/logOut';

    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          }),
        }
      )
      .pipe(map(() => this.isLoggedOut()));
  }

  public checkStatus(): Observable<boolean> {
    return this.sendCheckAuthRequest().pipe(
      map((res: any) => {
        this.handleResponse(res);
        if (!res.status) {
          this.isLoggedOut();
        }
        return !!res.status;
      })
    );
  }

  public register(info: AuthRegisterParams): Observable<any> {
    return this.apollo
      .mutate({
        mutation: mutations.signupMutation,
        variables: {
          createSignUp: {
            email: info.user.email,
            firstName: info.user.firstName,
            lastName: info.user.lastName,
            phone: info.company.phone,
            companyName: info.company.name,
            orgNr: info.company.orgNr,
          },
        },
      })
      .pipe(
        map(({ data }: any) => data.hantverksDokuSignupMutation),
        tap(data => this.messageService.insertDataFromMutation(data)),
        // Force all user data (menus, etc) to be reloaded
        tap(() =>
          this.appStateService.setAppState(appStateOptions.STARTUP_IN_PROGRESS)
        ),
        switchMap(data => this.checkStatus().pipe(map(() => data)))
      );
  }

  private sendLoginRequestToServer(
    username: string,
    password: string,
    rememberMe
  ) {
    const url = this.urlPrefix + '/hyperion/aut/login';
    const contentType = 'application/x-www-form-urlencoded; charset=UTF-8';

    const values = {
      LoginForm: {
        email: username,
        password: password,
        rememberMe: rememberMe,
      },
    };

    return this.http.post(url, values, {
      headers: new HttpHeaders({
        'Content-Type': contentType,
      }),
    });
  }

  private sendCheckAuthRequest() {
    // checks if logged in on server
    const url = this.urlPrefix + '/hyperion/aut/login';

    return this.http.post(
      url,
      {},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }),
      }
    );
  }

  /* --- CLEANUP METHODS --- */
  /* public clearData(x) {
    this.eraseRoutesForClient();
    this.clearLocaleStorage();
    return this.resetApolloStore();
  }
 */
  public clearData(currentState, prevState) {
    let retval;
    if (currentState === this.stateOptions.LOGGED_OUT) {
      this.eraseRoutesForClient();
      this.clearLocaleStorage();
      // retval = this.resetApolloStore();
      retval = of(this.resetApolloStore());
    } else {
      retval = of(this.eraseRoutesForClient());
    }

    return retval;
  }

  private resetApolloStore() {
    return this.apolloClient.cache.reset().then(x => x);
    // return observableFrom(this.apolloClient.resetStore());
  }

  private eraseRoutesForClient() {
    this.menuService.eraseMenu();
  }

  private clearLocaleStorage() {
    const url = localStorage.getItem('appappURL');
    localStorage.clear();
    this.resoterAppUrl(url);
  }

  resoterAppUrl(url) {
    localStorage.setItem('appappURL', url);
  }

  /* --- SETTERS --- */
  setUserLocaleStorage() {
    if (this.response) {
      this.meUser.setMEUser(this.response.user);
    }
  }
}
