import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, Observable, of as observableOf } from 'rxjs';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { FormHandlerService } from '../../shared/forms/index';
import { ApolloMutationService } from '../../shared/apollo/index';
import { InvoiceService } from './services/invoice.service';
import { CompanyInvoiceService } from './services/company-invoice.service';
import { InvoiceStateService } from './services/invoice-state.service';
import { MessageService } from '../../shared/message';
import { InvoiceParser } from './invoice-parser';
import { HtmlModalService } from 'app/shared/html-modal';
import { GlobalService } from '../../shared/global';
import { GlobalInvoiceService } from '../../global-services/invoice/invoice.service';

import { SavedOptionsComponent } from './saved-options/saved-options.component';
import { RowType } from './enums/row-type.enum';

import { ApolloQueryService } from 'app/shared/apollo';
import { InvoiceType } from './enums/invoice-type.enum';
import { InvoiceMode } from './enums/invoice-mode.enum';
import { AppDialogService } from 'app/shared/dialogs';
import { HelperService } from 'app/shared';
import { DateRange } from './date-range';
import { UserLocalStorageService } from 'app/shared/user';
import { ContactValidator } from 'app/shared/contacts/contact-validator';
import { CompanyInfo, CompanyInfoService } from 'app/shared/company';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-struktur-invoice',
  templateUrl: 'invoice-container.component.html',
  styleUrls: ['invoice-container.component.scss'],
  providers: [
    FormHandlerService,
    InvoiceService,
    CompanyInvoiceService,
    CompanyInfoService,
    InvoiceStateService,
    AppDialogService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceContainerComponent implements OnInit, OnDestroy {
  // From invoice index rows
  @Input() invoiceId;

  /** Whether we are in SupplierInvoice or Invoice */
  @Input() public context: 'invoice' | 'supplierInvoice' = 'invoice';

  private fDatum: Date;
  private ffDatum: Date;

  public hasBookkeepingIntegration: boolean;
  public bookkeepingIntegrationName: string;

  isDialog = true;
  customerInfo: any;
  noRot: boolean;
  sum = { netto: 0, sumRounded: 0 };
  invoiceForm: FormGroup;
  public typeInvoice = InvoiceType.Normal;
  type = 0;
  rotPersons;
  formFields = {
    invoiceBody: '',
    invoiceHead: '',
  };
  setFields = { headRotInfoForm: this.formFields };
  headRotInfoForm: FormGroup;
  rotForm = {
    name: '',
    personalNumber: '',
    nameOfProperty: '',
    housingAssociationOrgNumber: '',
    apartmentDesignation: '',
    customerWantedWorkAmount: '',
    wantedWorkAmount: '',
  };
  mode;
  saving = false;
  mutatedInvoiceId;
  openAllAccordionsHead = false;
  projectIdFromDialpgRef: string;
  data: any;
  projectShortMessage;
  selectedProjectFrmRow: string;
  subForm: Subscription;
  rotSubscription: Subscription;
  singleInvoiceSub: Subscription;
  attendanceReportProjectsSub: Subscription;
  formFromService = new Object();
  formsReady: Observable<boolean>;
  invoiceValues;
  betalningsvillkor;
  loading = true;
  invoice;
  invoiceType: number;
  public invoiceNumber: string;

  public selectByDateRange: DateRange;

  public selectByDate(event) {
    this.selectByDateRange = event;
    this.cdr.markForCheck();
  }

  constructor(
    private mutationService: ApolloMutationService,
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private invoiceStateService: InvoiceStateService,
    public companyInvoiceService: CompanyInvoiceService,
    public companyInfoService: CompanyInfoService,
    private formHandler: FormHandlerService,
    private messageService: MessageService,
    private dialogService: AppDialogService,
    private dialogConfig: DynamicDialogConfig,
    private htmlModalService: HtmlModalService,
    private globalService: GlobalService,
    private globalInvoiceService: GlobalInvoiceService,
    private apolloQueryService: ApolloQueryService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    public dialogRef: DynamicDialogRef,
    public helperService: HelperService,
    private userLocalStorageService: UserLocalStorageService,
    private activatedRoute: ActivatedRoute,
    private invoiceParser: InvoiceParser
  ) {}

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.initComponentWithDialogData();
    }

    this.activatedRoute.parent?.url.subscribe(res => {
      this.context = res[0]?.path === 'invoice' ? 'invoice' : 'supplierInvoice';
    });

    this.activatedRoute.params.subscribe(res => {
      this.invoiceId = res.id;

      if (this.invoiceId) {
        this.mode = 'update';
        this.isDialog = false;
        this._getAndloadInvoice();
      } else if (this.data && this.data.id && this.data.id > 0) {
        this.mode = 'copy';
        this.invoiceId = this.data.id;
        this.fDatum = this.data.fdatum;
        this.ffDatum = this.data.ffdatum;
        this._getAndloadInvoice();
      } else {
        this.loading = false;

        if (this.dialogConfig['data'] && this.dialogConfig['data']['type']) {
          this.type =
            this.dialogConfig['data']['type'] === 'supplierInvoice' ? 1 : 0;
        }

        this.mode = 'create';
      }

      this.createForm();

      this.subForm = this.companyInvoiceService.invoiceFormsChange$.subscribe(
        value => {
          value.hasOwnProperty('invoice') &&
            (this.formsReady = observableOf(true));
          this.formFromService = value; // Set forms for use for mutation.
          this.formHandler.setErrorFlag(false, value, 'submitted'); // Set rules to forms
          if (this.mode === 'create' && value.shipping) {
            this.setByCompanyInfo();
          }
        }
      );
      this.rotSubscription =
        this.invoiceStateService.invoiceTypeState$.subscribe(event => {
          this.noRot = false;
          this.invoiceType = event;
        });

      this.companyInfoService.companyInfo$.subscribe(
        (companyInfo: CompanyInfo) => {
          this.hasBookkeepingIntegration =
            companyInfo.hasBookkeepingIntegration;
          this.bookkeepingIntegrationName =
            companyInfo.bookkeepingIntegrationName;
        }
      );
    });
  }

  private initComponentWithDialogData() {
    if (this.dialogConfig['data']['vars']) {
      this.data = this.dialogConfig['data']['vars'];
    }
    if (this.dialogConfig['data']['projectIdFromDialpgRef']) {
      this.projectIdFromDialpgRef =
        this.dialogConfig['data']['projectIdFromDialpgRef'];
      this.loadProjectContactData(this.projectIdFromDialpgRef);
    }
    setTimeout(() => {
      this.dialogConfig.header = 'Skapa ny faktura';
    }, 0);
  }

  ngOnDestroy() {
    this.isDialog = true;
    this.subForm && this.subForm.unsubscribe();
    this.rotSubscription && this.rotSubscription.unsubscribe();
    this.singleInvoiceSub && this.singleInvoiceSub.unsubscribe();
    this.attendanceReportProjectsSub &&
      this.attendanceReportProjectsSub.unsubscribe();
  }

  _getAndloadInvoice() {
    this.globalInvoiceService
      .getSingleInvoiceFull(this.invoiceId, 'get')
      .then(invoice => {
        this.loadInvoice(invoice);
      });
  }

  closeDialog = (type = null) => {
    if (type === 'close') {
      this.confirmationService.confirm({
        message: 'Vill du verkligen stänga utan att skapa fakturan?',
        header: 'Bekräfta val',
        icon: 'fa fa-window-close',
        accept: () => this.dialogRef.close(),
        reject: () => {},
      });
    } else {
      this.dialogRef.close();
    }
  };

  changeSum = $event => (this.sum = $event);

  add(person): boolean {
    return (
      (
        '' +
        person.personalNumber +
        person.nameOfProperty +
        person.housingAssociationOrgNumber +
        person.apartmentDesignation +
        person.customerWantedWorkAmount +
        person.wantedWorkAmount
      )
        .replace(/null/gi, '')
        .replace(/undefined/gi, '').length > 3
    );
  }
  loadInvoice = value => {
    this.typeInvoice = +value.typeInvoice;
    let invoice: any;
    // Set id to null when mode is COPY
    if (this.mode === 'copy') {
      invoice = { ...value, id: null, fakturaNr: null };
      if (
        this.dialogConfig.data.vars.fdatum &&
        this.dialogConfig.data.vars.ffdatum
      ) {
        invoice.fdatum = this.dialogConfig.data.vars.fdatum;
        invoice.ffdatum = this.dialogConfig.data.vars.ffdatum;
      }
    } else {
      invoice = value;
    }

    this.invoiceNumber = invoice.fakturaNr;
    this.type = invoice.type;

    if (
      typeof this.formFromService['customer'] !== 'undefined' &&
      typeof this.formFromService['rot'] !== 'undefined'
    ) {
      this.rotPersons = invoice['invoiceRotRequestings'];
      const contact = Object.assign({}, invoice['contact']);
      if (!ContactValidator.isValidTrueId(contact.trueId)) {
        contact.trueId = '';
      }
      this.companyInvoiceService.updateSerchValue(contact); // invoice['contact'])  // Send to head for display in search bar
      this.formFromService['customer'].patchValue(contact); // invoice['contact'])
      this.formFromService['shipping'].patchValue(invoice);
      this.formFromService['invoice'].patchValue(invoice);

      this.formFromService['headCalender'].patchValue({
        fdatum: moment(invoice.fdatum).format(this.helperService.dateFormat()),
        ffdatum: moment(invoice.ffdatum).format(
          this.helperService.dateFormat()
        ),
      });

      this.loading = false;

      if (invoice['invoiceRotRequestingsExtra'] !== null) {
        this.formFromService['rot'].patchValue(
          invoice['invoiceRotRequestingsExtra']
        );
      }
      this.invoiceStateService.changeInvoiceMode([InvoiceMode.Free]);
      this.invoice = Object.assign(
        { source: invoice },
        this.invoiceParser.parseFromInvoice(invoice)
      );

      this.cdr.markForCheck();
    }
  }; // End loadInvoice

  public updateInvoiceFormProjectId(lastSelectedProjectId): void {
    if (typeof this.formFromService['invoice'] !== 'undefined') {
      this.formFromService['invoice'].patchValue({
        projectId: lastSelectedProjectId,
      });
    }
  }

  private loadProjectContactData(projectId: string): void {
    this.invoiceService.projectsInvoiceData([projectId]).subscribe(results => {
      const project = results[0];
      this.setProjectContactData(project, project.rots);
    });
  }

  private setProjectContactData(project, rotPersons = null): void {
    const contact = Object.assign({}, project['contact']);
    if (!ContactValidator.isValidTrueId(contact.trueId)) {
      contact.trueId = '';
    }
    this.customerInfo = contact; // project['contact']; // Set for customers for use on header
    this.formFromService['customer'].patchValue(contact); // project['contact'])
    this.companyInvoiceService.updateSerchValue(contact); // project['contact']) // Send to head for display in serach bar

    // Dont display kundnummer where trueId is 0
    // eslint-disable-next-line eqeqeq
    if (!ContactValidator.isValidTrueId(contact.trueId)) {
      this.formFromService['customer'].patchValue({ trueId: '' });
    }

    const ourReference = project.project?.madeBy
      ? project.project.madeBy
      : this.userLocalStorageService.getMEUser().fullName;

    const invoicePatchValue = {
      projectId: project.project?.id ?? project.id,
      ovrigt: project.ovrigt,
      our_reference: ourReference,
      your_reference: contact.name,
      your_order_nr: project.project?.orderNumber ?? project.orderNumber,
    };

    this.typeInvoice =
      Number(contact['reverseTax']) === 1
        ? InvoiceType.InvertedVat
        : InvoiceType.Normal;

    this.rotPersons = rotPersons;

    if (project['daDate'] != null) {
      Object.assign(invoicePatchValue, { ffdatum: project['daDate'] });
    }

    this.formFromService['invoice'].patchValue(invoicePatchValue);
  }

  public loadProjects(projectId: string[], invoiceType: InvoiceType): void {
    this.invoiceStateService.loadingInvoicesState(true);
    const projects = [];
    this.invoiceService.projectsInvoiceData(projectId).subscribe(results => {
      this.invoiceStateService.setLoadedProjectsAtas(
        results.map(result => result.atas).flat()
      );

      const firstProject = results[0];
      if (firstProject['project']['shortMessage'] !== null) {
        // avoid null....
        this.projectShortMessage =
          'Meddelande från projekt: ' + firstProject['project']['shortMessage'];
      }

      if (!firstProject.project.contact) {
        firstProject.project.contact = firstProject.contact;
      }

      this.typeInvoice = invoiceType;
      this.setProjectContactData(firstProject.project, firstProject.rots);

      results.forEach(res => projects.push(res));
      this.invoiceStateService.changeInvoice(
        this.invoiceParser.parse(projects, this.typeInvoice)
      );
    });
  } // End loadProjects

  public loadProjectToRow(projectId: string): void {
    this.selectedProjectFrmRow = projectId.toString();
    this.invoiceService
      .projectsInvoiceData([projectId])
      .subscribe(results =>
        this.invoiceStateService.addInvoiceByRow(
          this.invoiceParser.parse(results)
        )
      );
  } // End loadProjectToRow

  setByCompanyInfo() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyAllSettings', null, 'cache-and-network')
      .subscribe(data => {
        this.attendanceReportProjectsSub = data.sub;
        const company = data.data.company;
        this.betalningsvillkor = Number(company.betalningsvillkor); // To head for invoice date
        const attributes = {
          companyAdress:
            this.returnStringOnly(company.address) +
            ' ' +
            this.returnStringOnly(company.address2),
          companyAddressRow2:
            this.returnStringOnly(company.city) +
            ' ' +
            this.returnStringOnly(company.cityCode),
          companyFax: this.returnStringOnly(company.faxNr),
          companyName: this.returnStringOnly(company.name),
          companyMail: this.returnStringOnly(company.mail),
          companyOrgNr: this.returnStringOnly(company.orgNr),
          companyBankGiro: this.returnStringOnly(company.plusGiro),
          companyPostGiro: this.returnStringOnly(company.postGiro),
          companyIban: this.returnStringOnly(company.iban),
          companyBicSwift: this.returnStringOnly(company.bicSwift),
          companyTlfn: this.returnStringOnly(company.phone),
          companyWebb: this.returnStringOnly(company.webb),
        };

        this.formFromService['invoice'].patchValue({
          interest: company.interest,
        });
        this.formFromService['shipping'].patchValue(attributes);
      }); // End apolloQueryService.apolloWatchQueryTwo
  }

  returnStringOnly = value => (typeof value === 'string' ? value : '');

  createForm = () => (this.invoiceForm = this.fb.group(this.formFields));

  actionCreate($event) {
    this.saving = true;
    this.openAllAccordionsHead = false;
    this.createInvoice($event).subscribe(
      data => {
        this.formHandler.showServerErrorsOnAttributes(
          data,
          [
            {
              form: this.formFromService['customer'],
              argument: 'invoiceClientContact',
            },
            {
              form: this.formFromService['headCalender'],
              argument: 'createInvoice',
            },
            {
              form: this.formFromService['invoice'],
              argument: 'createInvoice',
            },
            {
              form: this.formFromService['shipping'],
              argument: 'createInvoice',
            },
            {
              form: this.formFromService['rot'],
              argument: 'invoiceRotRequestingExtra',
            },
            {
              form: this.formFromService['rotPersonInfo'],
              argument: 'invoiceRotRequestings',
            },
          ],
          true
        );

        this.mutationService.displayMutationStatus(data);

        this.saving = false;

        if (data.mutationSucceededAllArguments) {
          if ($event === 'prev') {
            const url = data['previewUrl'];

            this.htmlModalService.ny_sida(url, 900, 800);
          } else {
            this.globalInvoiceService.mutationOcured([this.mutatedInvoiceId]); // Refetch data into globalInvoice service

            if ('create' === $event) {
              this.closeDialog(); // Close dialog box
              this.dialogService.data = {
                vars: { invoice: { id: this.mutatedInvoiceId } },
              };
              this.dialogService.openComponent(SavedOptionsComponent, ' ');
            }
          }
        } else {
          this.openAllAccordionsHead = true;
        }
      },
      error => {
        this.saving = false;
      }
    );
  } // End actionCreate

  createInvoice(returnMode) {
    const invoiceDayTransactions = [];
    const invoiceDaySubsistenceTransactions = [];
    const invoiceProjectproductTransactions = [];
    const invoiceInstallamentransactions = [];

    const invoiceRows = [];
    let rotProvided = 0;

    this.formHandler.resetErrors(this.formFromService['rotPersonInfo']);

    this.formFromService['invoiceRows'].controls.rowDataContainers.controls.map(
      formGroup => {
        if (typeof formGroup.value.rowDetail !== 'undefined') {
          const mainTypeId = formGroup.value.mainTypeId;
          formGroup.value.rowDetail.forEach(ir => {
            // eslint-disable-next-line eqeqeq
            if (this.invoiceType == InvoiceType.Rot) {
              formGroup.value.rows.forEach(
                row => row.arbetskostnad && rotProvided++
              );
            }

            if (ir.used) {
              switch (mainTypeId) {
                case RowType.Installaments:
                  invoiceInstallamentransactions.push(+ir.id);
                  break;

                case RowType.Days:
                case RowType.DaysExtra:
                  invoiceDayTransactions.push(+ir.id);
                  break;

                case RowType.SubsistenceNight:
                case RowType.SubsistenceDay:
                case RowType.SubsistenceDayHalf:
                  invoiceDaySubsistenceTransactions.push(ir.id);
                  break;

                case RowType.Products:
                case RowType.ProductsExtra:
                  invoiceProjectproductTransactions.push(+ir.id);
                  break;
              }
            }
          });
        }

        formGroup.value.rows?.map(row => {
          const row2 = Object.assign({}, row);
          delete row2.open;
          delete row2.mileRow;
          delete row2.trueId;
          delete row2.rowDetailKey;
          delete row2.product;
          invoiceRows.push(row2);
        });
      }
    );

    const headCustomer = this.mutationService.getMutationDataFromForm(
      this.formFromService['customer']
    );
    const invoiceRotRequestingExtra =
      this.mutationService.getMutationDataFromForm(this.formFromService['rot']);
    const invoiceRotRequestings =
      this.formFromService['rotPersonInfo'].value.persons;

    const invoice = Object.assign(
      this.mutationService.getMutationDataFromForm(
        this.formFromService['invoice']
      ),
      this.mutationService.getMutationDataFromForm(
        this.formFromService['shipping']
      ),
      this.mutationService.getMutationDataFromForm(
        this.formFromService['footer']
      )
    );

    const invoiceTaxReductionRequests = [];
    let invoicetaxReductionsRequestingHasError = false;
    /* eslint-disable eqeqeq */
    if (this.globalInvoiceService.isTaxReduction(invoice.typeInvoice)) {
      /* eslint-enable eqeqeq */
      this.formFromService['rotPersonInfo'].controls.persons.controls.forEach(
        formGroup => {
          this.formHandler.setErrorFlag(false, formGroup, 'submitted');
        }
      );
      for (const i in invoiceRotRequestings) {
        const x = invoiceRotRequestings[i];
        delete x.__typename;
        invoiceTaxReductionRequests.push(x);
      }
      this.formHandler.setErrorFlag(
        false,
        this.formFromService['rot'],
        'submitted'
      );

      invoicetaxReductionsRequestingHasError =
        !this.formFromService['rotPersonInfo'].valid ||
        !this.formFromService['rot'].valid ||
        this.invoiceStateService.taxReductionErrors !== null;
    }

    invoice['type'] = this.type;

    return Observable.create(observer => {
      const invoiceValues = {
        createInvoice: invoice, // conact sum
        invoiceClientContact: headCustomer,
        invoiceRows: invoiceRows,
        invoiceRotRequestings: invoiceTaxReductionRequests,
        invoiceRotRequestingExtra: invoiceRotRequestingExtra,
        invoiceDayTransactions: invoiceDayTransactions,
        invoiceDaySubsistenceTransactions: invoiceDaySubsistenceTransactions,
        invoiceProjectproductTransactions: invoiceProjectproductTransactions,
        invoiceInstallamentransactions: invoiceInstallamentransactions,
        returnMode: returnMode,
      };

      this.invoiceValues = invoiceValues;

      // Check forms for error before flight to server
      const forms = [
        this.formFromService['customer'],
        this.formFromService['invoice'],
        this.formFromService['shipping'],
      ];

      if (
        invoicetaxReductionsRequestingHasError ||
        !this.formHandler.checkFormsValidity(forms)
      ) {
        this.messageService.insertData({
          textArray: ['Fakturan skapades inte, kontrollera formulären'],
          time: 0,
          type: 'error',
        });
        this.saving = false;
        this.openAllAccordionsHead = true;
      } else {
        const sub = this.mutationService
          .staticConstructQueryAndExecuteMutation(
            'createInvoiceMutation',
            invoiceValues,
            'invoice'
          )
          .subscribe(
            data => {
              sub.unsubscribe();
              this.mutatedInvoiceId = +data['id'];
              observer.next(data);
            },
            error => {
              observer.error(error);
            }
          );
      }
    });
  }

  public goToInvoice(invoiceId: number): void {
    window.location.href = this.context + '/' + String(invoiceId);
  }
}
