<div class="layout-wrapper">
  <div *ngIf="loading" class="loading-screen">
    <div class="bar"></div>
  </div>

  <app-sidebar-menu></app-sidebar-menu>

  <div class="layout-main">
    <div class="layout-content" style="margin-top: 9rem">
      <app-struqtur-payment-reminder-notification
        (isLockedAccount)="updateLockedAccount($event)"
      ></app-struqtur-payment-reminder-notification>
      <app-struqtur-trial-period-notification></app-struqtur-trial-period-notification>
      <app-struqtur-crm></app-struqtur-crm>
      <ng-container *ngIf="!isAccountLocked">
        <router-outlet></router-outlet>
        <hyperion-footer></hyperion-footer>
      </ng-container>
    </div>
  </div>
</div>
