import { Component, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';

import { MessageService } from 'primeng/api';

import { IdleComponent } from '../idle/idle.component';
import { LockedScreen } from '../idle/modals/locked-screen';
import { TimeoutWarning } from '../idle/modals/timeout-warning';
import { MessageService as VendiumMessage } from '../message/index';

import { ModalBridgeService, IdleService } from 'app/shared/idle/index';
import { AuthService } from 'app/shared/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from 'app/shared/config/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'hyperion-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [MessageService],
})
export class HyperionFooter implements OnInit {
  generalMsgs = [];
  apolloErrorMsgs = [];
  @ViewChild('timeout', { static: true }) timeout: TimeoutWarning;
  @ViewChild('locked', { static: true }) locked: LockedScreen;
  @ViewChild('idle') idle: IdleComponent;
  display = {
    locked: false,
    timeout: false,
  };
  modalSubscriber;
  year;
  latestModal = {
    active: false,
    name: '',
  };
  newMessage = false;
  timeOutStarted = false;
  showIdle = new BehaviorSubject(false);

  constructor(
    private vendiumMessage: VendiumMessage,
    private modalBridgeService: ModalBridgeService,
    private authService: AuthService,
    private idleService: IdleService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private router: Router,
    public configService: ConfigService
  ) {
    this.generalMsgs = this.vendiumMessage.msgs.general;
    this.apolloErrorMsgs = this.vendiumMessage.msgs.error;

    this.modalSubscriber = this.modalBridgeService.modalSubject.subscribe(
      value => {
        this.modalSubjectListener(value);
      }
    );

    this.vendiumMessage.msgsSubjects.subscribe(data => {
      if (data['duration']) {
        data['life'] = data['duration'];
      }

      this.messageService.add(data);
    });

    this.authService.gotLoggedOut.asObservable().subscribe(data => {
      if (data) {
        this.showIdle.next(false);
      }
    });
  }

  addMsg(dataParam) {
    this.generalMsgs.push(dataParam);
    this.setHideTimeout();
  }

  setHideTimeout() {
    this.timeOutStarted = true;
    setTimeout(() => {
      if (!this.newMessage) {
        this.generalMsgs = [];
        this.timeOutStarted = false;
      } else {
        this.newMessage = false;
        this.setHideTimeout();
      }
    }, 4500);
  }

  ngOnInit() {
    this.year = new Date().getFullYear();

    this.idleService.idleControl.asObservable().subscribe(data => {
      const val: string = data;
      if (val === 'start') {
        this.showIdle.next(true);
      } else if (val === 'stop') {
        this.showIdle.next(false);
      }
      this.cdr.detectChanges();
    });
  }

  modalSubjectListener(subjectValue) {
    const toggleEvent = {
      action: 'open',
      name: subjectValue,
    };

    this.toggleModal(toggleEvent);
  }

  toggleModal(event) {
    if (this.router.url === '/login') {
      return;
    }

    let actionBool: boolean;
    if (event['action'] === 'open') {
      actionBool = true;
      if (event['name'] === 'timeout') {
        this.timeout.init();
      } else {
        this.locked.init();
        this.showIdle.next(false);
      }
    } else {
      actionBool = false;
    }

    this.latestModal['name'] = event['name'];
    this.latestModal['active'] = actionBool;
    this.display[event['name']] = actionBool;
  }

  closeModal() {
    this.display[this.latestModal['name']] = false;
    this.latestModal['active'] = false;
  }
}
