import { gql } from 'apollo-angular';

import { mutationDetails } from 'app/shared/apollo/queries';

export const SignUp = gql`
  mutation signupMutation(
    $createSignUp: HantverksDokuSignupMutationInput
    ) {
    hantverksDokuSignupMutation(
      createSignUp: $createSignUp
    ) {
      ${mutationDetails}
    }
  }
`;
